import React, {useEffect} from 'react'; // Import MainLogo component here
import MainLogo from '../components/common/logos/mainLogo';
import '../app.scss';
import classes from './auth.module.scss';
interface AuthPageLayoutProps {
  children: React.ReactNode;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({children}) => {
  return (
    <div className={classes.authWrapper}>
      <div
        className={classes.authInnerBlock}
        style={{
          minWidth: '50%',
          maxWidth: '1100px',
          height: 700,
        }}>
        <div className={`${classes.authWrapperInner} ${classes.authHeader}`}></div>

        <div style={{width: '100%'}}>{children}</div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
