import React, {useState} from 'react';

// import { action } from '@storybook/addon-actions';

import {
  Scanner as ScannerComp,
  outline,
  boundingBox,
  centerText,
  useDevices,
} from '@yudiel/react-qr-scanner';

const styles = {
  container: {
    width: 400,
    margin: 'auto',
  },
  controls: {
    marginBottom: 8,
  },
};

const BarCodeReader = ({onScan, ...args}) => {
  const [deviceId, setDeviceId] = useState(undefined);
  const [tracker, setTracker] = useState('centerText');

  const [pause, setPause] = useState(false);

  const devices = useDevices();
  function getTracker() {
    switch (tracker) {
      case 'outline':
        return outline;
      case 'boundingBox':
        return boundingBox;
      case 'centerText':
        return centerText;
      default:
        return undefined;
    }
  }

  return (
    <div
      className={
        'relative col-12 border-primary-custom pt-4 pb-4 greyMenuBackground color-white custom-border   px-4 '
      }
      style={styles.container}>
      <div style={styles.controls}>
        <select onChange={e => setDeviceId(e.target.value)}>
          <option value={undefined}>Select a device</option>
          {devices.map((device, index) => (
            <option key={index} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
        <select style={{marginLeft: 5}} onChange={e => setTracker(e.target.value)}>
          <option value="centerText">Center Text</option>
          <option value="outline">Outline</option>
          <option value="boundingBox">Bounding Box</option>
          <option value={undefined}>No Tracker</option>
        </select>
      </div>
      <ScannerComp
        {...args}
        formats={[
          'maxi_code',
          'pdf417',
          'aztec',

          'dx_film_edge',
          'databar',
          'codabar',
          'code_39',
          'code_93',
          'code_128',
          'ean_8',
          'ean_13',
          'linear_codes',
          'upc_a',
          'upc_e',
        ]}
        constraints={{
          deviceId: deviceId,
        }}
        onScan={detectedCodes => {
          if (detectedCodes?.length > 0) {
            onScan(detectedCodes[0].rawValue);
          }
          // action('onScan')(detectedCodes);
        }}
        onError={error => {
          console.log(`onError: ${error}'`);
        }}
        components={{
          audio: true,
          onOff: true,
          torch: true,
          zoom: true,
          finder: true,
          tracker: getTracker(),
        }}
        allowMultiple={true}
        scanDelay={2000}
        paused={pause}
      />
    </div>
  );
};

export default BarCodeReader;
