import React, {ChangeEvent, useState, useRef, useEffect} from 'react';
import {FoodDTO, Macronutrients} from '../../../../models/mealModel';
import {EditMacronutrientsComponent} from '../../macronutries/editMacronutrientsComponent';
import {useTranslation} from 'react-i18next';
import modalClasses from './../../../common/modal/styles/modalShared.module.scss';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import {AddUpdatePortionsComponent} from '../addUpdatePortions/addUpdatePortions.component';
import ModalWrapper from '../../../common/modal/modalWrapper.component';
import classColor from '../../../shared/sharedColorConfigurations.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import FoodInput from '../foodInput/foodInput';
export interface AddEditFoodComponentProperties {
  food: FoodDTO;
  barCode?: string | undefined;
  saveHandler: (params: FoodDTO) => void;
  macronutrients?: Macronutrients;
}

// Define the type of the ref for EditMacronutrientsComponent
interface EditMacronutrientsComponentRef {
  getData: () => Macronutrients;
  setData: (macronutients: Macronutrients) => void;
}

export function AddEditFoodComponent({
  food,
  barCode,
  saveHandler,
  macronutrients,
}: AddEditFoodComponentProperties) {
  const [currentFood, setCurrentFood] = useState<FoodDTO>(food);
  const [isModal, setModal] = useState<boolean>(false);
  // Define the ref with the correct type instead of using `any`
  const macronutrientsChildComponentRef = useRef<EditMacronutrientsComponentRef>(null);

  function Save() {
    const childData = macronutrientsChildComponentRef.current?.getData();
    currentFood.macronutrients = childData;

    saveHandler(currentFood);
  }
  useEffect(() => {
    setCurrentFood(prev => ({...prev, barCode: barCode}));
  }, [barCode]);
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentFood(prev => ({...prev, [event.target.name]: event.target.value}));
  };
  const addPortion = (value: number) => {
    setCurrentFood(prevFood => ({
      ...prevFood,
      portions: prevFood.portions
        ? prevFood.portions.includes(value)
          ? prevFood.portions
          : [...prevFood.portions, value]
        : [value],
    }));
  };

  const removePortion = (value: number) => {
    setCurrentFood(prevFood => ({
      ...prevFood,
      portions: prevFood.portions ? prevFood.portions.filter(p => p !== value) : [],
    }));
  };
  useEffect(() => {
    if (macronutrients != null) {
      macronutrientsChildComponentRef.current?.setData(macronutrients);
    }
  }, [macronutrients]);
  const {t} = useTranslation();

  return (
    <div className="relative col-12 border-primary-custom pt-4 mb-4 greyMenuBackground color-white custom-border   px-4">
      <ModalWrapper
        handleClose={() => {
          setModal(false);
        }}
        isOpen={isModal}>
        <div className="relative w-[222px] col-12 border-primary-custom pt-4 mb-0 pb-4 greyMenuBackground color-white custom-border   px-4">
          <FoodInput
            getNewFood={(foodNew: FoodDTO) => {
              if (foodNew?.macronutrients != undefined && foodNew.macronutrients != null) {
                macronutrientsChildComponentRef.current?.setData(foodNew.macronutrients);
                console.log(foodNew);
              }
              setModal(false);
            }}
          />
        </div>
      </ModalWrapper>
      <span
        key={food.id}
        onClick={event => {
          event.stopPropagation();
        }}
        className="">
        <form onSubmit={Save}>
          <div style={{color: 'white', textAlign: 'left'}}>
            <div className="row">
              <div className="col-6">
                <label htmlFor="name" className="mr-2">
                  {t('Name')}
                </label>
                <input
                  type="text"
                  id="name"
                  className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
                  name="name"
                  onChange={changeHandler}
                />
              </div>{' '}
              <div className="col-6">
                <label htmlFor="name" className="mr-2">
                  {t('Brand')}
                </label>
                <input
                  type="text"
                  id="brand"
                  className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
                  name="brand"
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                onClick={() => {
                  setModal(true);
                }}
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '10px',
                  fontSize: '30px',
                  cursor: 'pointer',
                }}
                className={classColor.sidebarColor}
                icon={faCopy}></FontAwesomeIcon>
            </div>
            <div>
              <label htmlFor="description" className="mr-2">
                {t('Description')}
              </label>
              <input
                type="text"
                id="description"
                className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
                name="description"
                onChange={changeHandler}
              />
            </div>
            {food.macronutrients && (
              <EditMacronutrientsComponent
                ref={macronutrientsChildComponentRef}
                macronutrients={food.macronutrients}
              />
            )}
            <div className="col-6">
              <label htmlFor="name" className="mr-2">
                {t('Barcode')}
              </label>
              <input
                type="text"
                id="barcode"
                className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
                name="barcode"
                value={currentFood.barCode}
                onChange={changeHandler}
              />
            </div>
            <AddUpdatePortionsComponent
              portions={currentFood.portions ?? []}
              addHandler={addPortion}
              removeHandler={removePortion}
            />
          </div>
        </form>
        <span className={modalClasses.saveBtn} onClick={Save}>
          +
        </span>
      </span>
    </div>
  );
}
