import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {
  AuthenticationTokenModel,
  KcalFormulaEnum,
  LoginUserCommand,
  UserInfoDTO,
} from '../../../models/accountModel';
import {RootState} from '../..';
import {UserInfoObject} from '../../../models/strict/accountStrictModel';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import API_BASE_URL from '../../../app.config';
import axios from '../../../axios';
interface AuthState {
  isAuthenticated: boolean;
  userId: string;
  user: UserInfoObject;
  access: string;
  refresh: string;
  email: string;
}
export const ACCESS_KEY = 'fitaccess';
export const EMAIL_KEY = 'fitemail';
export const EXPIRES_KEY = 'fitexpires';
export const REFRESH_KEY = 'fitrefresh';
export const USER_ID_KEY = 'fituserid';
export const USER_INFO_KEY = 'fituserinfo';

function getInitialState(): AuthState {
  const expiresIn = localStorage.getItem(EXPIRES_KEY);

  if (expiresIn && new Date() > new Date(expiresIn)) {
    return {
      isAuthenticated: false,
      access: '',
      refresh: localStorage.getItem(REFRESH_KEY) ?? '',
      email: localStorage.getItem(EMAIL_KEY) ?? '',
      userId: localStorage.getItem(USER_ID_KEY) ?? '',
      user: new UserInfoObject(),
    };
  }

  const userInfo = localStorage.getItem(USER_INFO_KEY);
  return {
    isAuthenticated: Boolean(localStorage.getItem(ACCESS_KEY)),
    access: localStorage.getItem(ACCESS_KEY) ?? '',
    email: localStorage.getItem(EMAIL_KEY) ?? '',
    refresh: localStorage.getItem(REFRESH_KEY) ?? '',
    userId: localStorage.getItem(USER_ID_KEY) ?? '',
    user: userInfo ? JSON.parse(userInfo) : new UserInfoObject(),
  };
}

const initialState: AuthState = getInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.access = '';
      state.email = '';
      // localStorage.removeItem(ACCESS_KEY);
      // localStorage.removeItem(EMAIL_KEY);
      // localStorage.removeItem(EXPIRES_KEY);
      // localStorage.removeItem(USER_ID_KEY);
      // localStorage.removeItem(USER_INFO_KEY);
      // localStorage.removeItem(REFRESH_KEY);
    },
    loginFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setUserInfoState(state, action: PayloadAction<UserInfoDTO>) {
      if (action.payload) {
        state.user = action.payload as UserInfoObject;
        localStorage.setItem(USER_INFO_KEY, JSON.stringify(action.payload));
      }
    },
    setKcalFormula(state, action: PayloadAction<KcalFormulaEnum>) {
      state.user.kcalFormulaEnum = action.payload;
    },
    loginSuccess(state, action: PayloadAction<AuthenticationTokenModel>) {
      // state.email = action.payload.email
      state.isAuthenticated = true;

      if (action.payload.accessToken) {
        state.access = action.payload.accessToken;
        localStorage.setItem(ACCESS_KEY, action.payload.accessToken.toString());
      }
      if (action.payload.refreshToken) {
        state.refresh = action.payload.refreshToken;
        localStorage.setItem(REFRESH_KEY, action.payload.refreshToken.toString());
      }
      // localStorage.setItem(email_KEY, action.payload.)
      if (action.payload.expiration) {
        localStorage.setItem(EXPIRES_KEY, new Date(action.payload.expiration).toString());
      }
      if (action.payload.userId) {
        state.userId = action.payload.userId;
        localStorage.setItem(USER_ID_KEY, action.payload.userId);
      }
      if (action.payload.userInfo) {
        state.user = action.payload.userInfo as UserInfoObject;
        localStorage.setItem(USER_INFO_KEY, JSON.stringify(action.payload.userInfo));
      }
      window.location.href = '/';
    },
  },
});

export const selectCurrentUserToken = (state: RootState) => state.authReducer.access;
export const selectCurrentUserRefresh = (state: RootState) => state.authReducer.refresh;
export const selectCurrentUser = (state: RootState) => state.authReducer.user;
export const selectCurrentUserId = (state: RootState) => state.authReducer.userId;
export const selectIsAuthenticated = (state: RootState) => state.authReducer.isAuthenticated;
export default authSlice.reducer;
