import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../../../hooks/debounce';
import {FoodDTO} from '../../../../models/mealModel';
import axios from 'axios';
import classes from './foodInput.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {addFood, getFoods} from '../../../../actions/meals/food.actions';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalWrapper from '../../../common/modal/modalWrapper.component';
import {AddEditFoodComponent} from '../foodAddUpdateComponent/AddEditFoodComponent';
import {FoodDTOObject, MacronutrientsObject} from '../../../../models/strict/mealStrictModel';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import FoodListPresentation from '../foodListPresentation.component';

export interface FoodInputProperties {
  getNewFood: (params: FoodDTO) => void;
  onClickBarScannerHandler?: () => void;
  restaurantId?: string;
  userId?: string;
}

export default function FoodInput({
  getNewFood,
  restaurantId,
  userId,
  onClickBarScannerHandler,
}: FoodInputProperties) {
  const [value, setValue] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(value, 500);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const {foods} = useAppSelector(state => state.foodReducer);

  async function searchFoods(search: string) {
    if (abortController) {
      abortController.abort();
    }
    console.log(restaurantId);
    const controller = new AbortController();
    setAbortController(controller);
    dispatch(getFoods(controller.signal, search, restaurantId));
  }

  const {t} = useTranslation();

  useEffect(() => {
    if (debounced.length >= 3) {
      searchFoods(debounced).then(() => setDropdown(true));
    } else {
      setDropdown(false);
    }
  }, [debounced]);

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function renderDropdown() {
    if (foods.length === 0 || foods == undefined) {
      return <p className="text-center"> </p>;
    }

    return (
      <FoodListPresentation
        foods={foods}
        onClickHandler={selectedFood => {
          getNewFood(selectedFood);
          setValue('');
        }}
      />
    );
  }

  return (
    <div className="mb-4 my-2 relative">
      <label className="text-white" htmlFor="weight">
        {t('Food')}{' '}
      </label>

      <div className="w-full flex my-4" style={{display: 'flex', justifyContent: 'space-around'}}>
        <input
          className={classesModal.inputModal + ' col-8  w-full outline-0 h-[42px]'}
          type="text"
          onChange={changeHandler}
          value={value}
          placeholder="Search for food..."
        />
        {onClickBarScannerHandler && (
          <div
            className={classesModal.addBtn}
            onClick={() => {
              onClickBarScannerHandler();
            }}>
            <FontAwesomeIcon icon={faBarcode}></FontAwesomeIcon>
          </div>
        )}
      </div>

      {dropdown && (
        <ul className={classes.foodWrapper + '  ' + classes.dropdownItems}>{renderDropdown()}</ul>
      )}
    </div>
  );
}
