import React from 'react';
import classes from '../../../layouts/auth.module.scss';

interface AuthButtonProperties {
  labelValue: string;
  onClickHandler?: () => void;
  isSubmit?: boolean;
}

export function AuthButton({onClickHandler, labelValue, isSubmit}: AuthButtonProperties) {
  if (isSubmit) {
    return (
      <div className={`${classes.auth} col-12`}>
        <input
          type="submit"
          className={`${classes.auth} ${classes.authSubmit} ${classes.buttonAuthFull}`}
          value={labelValue}
        />
      </div>
    );
  } else {
    return (
      <div className={`${classes.auth} ${classes.authSubmit} ${classes.buttonAuthFull}`}>
        <span
          className={classes.authSubmit}
          onClick={() => {
            if (onClickHandler != null) {
              onClickHandler();
            }
          }}>
          {labelValue}
        </span>
      </div>
    );
  }
}
