import React, {ChangeEvent, useEffect, useState} from 'react';
import {FoodDTO, FoodMealDTO, MealDTO} from '../../../../models/mealModel';
import MealList from '../mealList';
import {FoodMealList} from '../../foodMeals/foodMealsList';
import {
  FoodDTOObject,
  FoodMealObject,
  MacronutrientsObject,
  MealObject,
} from '../../../../models/strict/mealStrictModel';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DateTimePicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';
import {largeCommonDateFormat} from '../../../../constants/common/dateFormat';
import {AddEditFoodComponent} from '../../foods/foodAddUpdateComponent/AddEditFoodComponent';
import {addFood} from '../../../../actions/meals/food.actions';
import {useAppDispatch} from '../../../../hooks/redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {DateInputProps} from '../../../../constants/common/dateInputProps';
import AddHistoryIcon from '../../../common/icon/addHistoryIcon/addHistoryIcon.component';
import classes from './addEditMealComponent.module.scss';
import modalClasses from './../../../common/modal/styles/modalShared.module.scss';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import {deleteFoodMeal} from '../../../../actions/meals/meal.action';
import {useTranslation} from 'react-i18next';

export interface AddEditMealComponentProperties {
  meal: MealDTO;
  isChangeDateShow?: boolean;
  choosenDate?: DateTime;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
}
export default function AddEditMealComponent({
  meal,
  isChangeDateShow,
  saveHandler,
  choosenDate,
}: AddEditMealComponentProperties) {
  const [foodMeal, setFoodMeal] = useState<MealDTO>();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  useEffect(() => {
    if (meal.foods == null) {
      meal.foods = [];
    }
    if (meal.eatTime == null) {
      if (choosenDate != null) {
        meal.eatTime = choosenDate.toFormat(largeCommonDateFormat);
      } else {
        meal.eatTime = DateTime.now().toFormat(largeCommonDateFormat);
      }
    }
    setFoodMeal(meal);
  }, [meal]);

  const removeFoodFromMeal = (removedMeal: FoodMealDTO) => {
    const index = foodMeal?.foods?.indexOf(removedMeal);
    if (index !== -1) {
      if (removedMeal.id != null) {
        dispatch(deleteFoodMeal(removedMeal.id)).then(() => {
          const newFoodsArray = foodMeal?.foods?.filter((_, i) => i !== index);
          setFoodMeal(prevState => ({
            ...prevState,
            foods: newFoodsArray,
          }));
        });
      } else {
        const newFoodsArray = foodMeal?.foods?.filter((_, i) => i !== index);
        setFoodMeal(prevState => ({
          ...prevState,
          foods: newFoodsArray,
        }));
      }
    }
  };
  return (
    <div
      key={meal.id}
      className="row relative col-12 border-primary-custom pt-4 greyMenuBackground color-white custom-border   px-4"
      style={{margin: '0'}}>
      {meal.eatTime && isChangeDateShow && (
        <div>
          <DateTimePicker
            label="Controlled picker"
            value={DateTime.fromFormat(meal?.eatTime, largeCommonDateFormat)}
            slotProps={DateInputProps.dateTimeSlots}
            onChange={newValue => {
              newValue
                ? setFoodMeal(prevState => ({
                    ...prevState,
                    eatTime: newValue.toFormat(largeCommonDateFormat),
                  }))
                : console.log(newValue);
            }}
          />
        </div>
      )}
      {foodMeal?.foods && (
        <>
          <FoodMealList
            list={foodMeal?.foods}
            addFoodHandler={food => {
              setFoodMeal(prevState => ({
                ...prevState,
                foods: [...(prevState?.foods || []), food],
              }));
            }}
            removeFoodHandler={removeFoodFromMeal}
          />
        </>
      )}
      {foodMeal?.foods != null && foodMeal?.foods.length > 0 && (
        <>
          <h3 style={{display: 'inline-block', textAlign: 'center'}}>
            <div className={classesColor.sidebarColor}>{t('M_total')}</div>
          </h3>
          <div className={classes.totalContainerWrapper}>
            <div className={classes.totalContainer}>
              <p style={{display: 'inline-block', margin: 3}}>
                <div className={classesColor.sidebarColor}>{t('M_calories')}</div>
                {foodMeal.foods
                  .reduce((acc, value) => {
                    const weight = value.weight || 0;
                    const caloriesPer100g = value.food?.macronutrients?.calories || 0;
                    return acc + (caloriesPer100g * weight) / 100;
                  }, 0)
                  .toFixed(2)}
              </p>
              <p style={{display: 'inline-block', margin: 3}}>
                <div className={classesColor.sidebarColor}>{t('M_carbohydrates')}</div>
                {foodMeal.foods
                  .reduce((acc, value) => {
                    const weight = value.weight || 0;
                    const carbsPer100g = value.food?.macronutrients?.carbohydrates || 0;
                    return acc + (carbsPer100g * weight) / 100;
                  }, 0)
                  .toFixed(2)}
              </p>
              <p style={{display: 'inline-block', margin: 3}}>
                <div className={classesColor.sidebarColor}>{t('M_fats')}</div>
                {foodMeal.foods
                  .reduce((acc, value) => {
                    const weight = value.weight || 0;
                    const fatsPer100g = value.food?.macronutrients?.fats || 0;
                    return acc + (fatsPer100g * weight) / 100;
                  }, 0)
                  .toFixed(2)}
              </p>
              <p style={{display: 'inline-block', margin: 3}}>
                <div className={classesColor.sidebarColor}>{t('M_proteins')}</div>
                {foodMeal.foods
                  .reduce((acc, value) => {
                    const weight = value.weight || 0;
                    const proteinsPer100g = value.food?.macronutrients?.proteins || 0;
                    return acc + (proteinsPer100g * weight) / 100;
                  }, 0)
                  .toFixed(2)}
              </p>
            </div>
          </div>
          <p
            className={modalClasses.saveBtn}
            onClick={() => {
              saveHandler(foodMeal);
            }}>
            +
          </p>
        </>
      )}
      {/* )} */}
    </div>
  );
}
