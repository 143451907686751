import React, {ChangeEvent, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDoorClosed,
  faDoorOpen,
  faHourglassHalf,
  faSave,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  getStatusName,
  GroupDto,
  Status,
  UserGroupDto,
  UserGroupRoleEnum,
} from '../../../../models/strict/accountStrictModel';
import AddHistoryIcon from '../../../common/icon/addHistoryIcon/addHistoryIcon.component';
import MainLogo from '../../../common/logos/mainLogo';
import {useAppDispatch} from '../../../../hooks/redux';
import {group} from 'console';
import {useSelector} from 'react-redux';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import classes from './groupsItem.module.scss';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import FAvatar from '../../../common/logos/fAvatar';
import MAvatar from '../../../common/logos/mAvatar';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {DeleteGroup} from '../../../../actions/account/group.actions';
import ModalWrapper from '../../../common/modal/modalWrapper.component';
import ConfirmModal from '../../../common/modal/confirmAction.component';
import classColor from '../../../shared/sharedColorConfigurations.module.scss';
import {DateTime} from 'luxon';
import {shortCommonDateFormat} from '../../../../constants/common/dateFormat';
export interface GroupItemComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupDto: GroupDto;
  addUserHandler: (id: string) => void;
  deleteGroupHandler: () => void;
  removeUserHandler: (email: string) => void;
  removeUserHandlerById: (userId: string) => void;
}
export default function GroupItemComponent({
  groupDto,
  addUserHandler,
  removeUserHandler,
  deleteGroupHandler,
  removeUserHandlerById,
}: GroupItemComponentProperties) {
  const {t} = useTranslation();
  const currentUserId = useSelector(selectCurrentUserId);
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  const [confirmAction, setConfirmAction] = useState<{method: Function; params: any[]} | null>(
    null,
  );
  const IsAdmin = () => {
    if (groupDto != null && groupDto.userGroups != null) {
      const currentUser = groupDto?.userGroups?.find(f => f.userId == currentUserId);
      if (currentUser != null) {
        return currentUser.roles?.find(f => f == UserGroupRoleEnum.Owner) != null;
      }
      return false;
    }
  };
  const renderContent = (f: UserGroupDto) => (
    <>
      <div
        className={classColor.sidebarColor}
        style={{position: 'absolute', right: '10px', top: '10px'}}>
        {IsAdmin() && (
          <FontAwesomeIcon
            style={{cursor: 'pointer'}}
            icon={faXmark}
            onClick={() => {
              removeUserHandler(f.email);
            }}></FontAwesomeIcon>
        )}
      </div>
      {f.status == Status.Waiting && (
        <div className={classes.statusOfInvitation}>
          <FontAwesomeIcon icon={faHourglassHalf} />
        </div>
      )}
      <div style={{display: 'flex'}}>
        <div className={classes.logoContainer}>
          {f.status == Status.Accepted ? (
            <MAvatar
              withoutText={true}
              width={70}
              onClickHandler={() => {
                if (f.status == Status.Accepted) {
                  window.location.href = `/profile/${f.userId}`;
                }
              }}></MAvatar>
          ) : (
            <MAvatar width={70} withoutText={true}></MAvatar>
          )}
        </div>
        <div className={classes.footerOfCard} style={{textAlign: 'center'}}>
          <div style={{color: 'white'}}>
            {f.user == null ? f.email : `${f.user.firstName} ${f.user.lastName}`}
          </div>
          {f.dateJoin == null && (
            <div className={classesColor.sidebarColor}>{t('Expired at:')}</div>
          )}
          {f.dateJoin != null && (
            <div className={classesColor.sidebarColor}>
              {t('Joined at:')}{' '}
              <span style={{color: 'white'}}>
                {DateTime.fromISO(f.dateJoin.toString()).toFormat(shortCommonDateFormat)}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="actions"></div>
    </>
  );

  return (
    <div className="my-4 row col-12 relative" style={{marginLeft: 0}}>
      <ConfirmModal
        isOpen={confirmAction !== null}
        handleAgree={() => {
          if (confirmAction) {
            confirmAction.method(...confirmAction.params);
            setConfirmAction(null);
          }
        }}
        handleClose={() => {
          setConfirmAction(null);
        }}></ConfirmModal>
      <div className={'col-12 ' + classes.headerOfGroup}>
        <div className={classes.groupTitle + ' my-2 ' + classesColor.sidebarColor}>
          {groupDto.name}
        </div>
        <div className={classes.action}>
          <FontAwesomeIcon
            style={{cursor: 'pointer'}}
            className={classesColor.sidebarColor + ' mx-2'}
            icon={faDoorOpen}
            onClick={() => {
              setConfirmAction({
                method: removeUserHandlerById,
                params: [currentUserId],
              });
            }}
          />
          {IsAdmin() && (
            <>
              <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                className={classesColor.sidebarColor + ' mx-2'}
                icon={faXmark}
                onClick={() => {
                  setConfirmAction({
                    method: deleteGroupHandler,
                    params: [],
                  });
                }}
              />
              <div className="mx-2">
                <AddHistoryIcon
                  onClickHandler={() => {
                    addUserHandler(groupDto.id);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.userList}>
        <ScrollableBody maxHeight="150px" className={'flex flex-row'}>
          {groupDto?.userGroups?.map((f, index) =>
            f.status === Status.Accepted ? (
              <a
                key={index}
                className={classes.groupItem + ' ' + getStatusName(f.status)}
                href={`/profile/${f.userId}`}>
                {renderContent(f)}
              </a>
            ) : (
              <div key={index} className={classes.groupItem + ' ' + getStatusName(f.status)}>
                {renderContent(f)}
              </div>
            ),
          )}
        </ScrollableBody>
      </div>
    </div>
  );
}
