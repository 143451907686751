import {DateTime} from 'luxon';
import React, {useEffect, useRef, useState} from 'react';
import {SessionDto, TrainingDto, TrainingDescriptionDto} from '../../../../../models/trainingModel';
import {Session} from 'inspector';
import Timer from '../../../../common/timer/timer.component';
import AddEditTrainingComponent from '../../../trainings/addEditTraining/addEditTraining.component';
import TrainingDescriptionInput from '../../../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {TrainingObject} from '../../../../../models/strict/sessionStrictModel';
import TagList from '../../../tag/tagList.component';
import ConfirmModal from '../../../../common/modal/confirmAction.component';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleRight, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {longCommonTimeFormat12} from '../../../../../constants/common/dateFormat';
import TrainingStateComponent from '../trainingState/trainingState.module';
import classes from '../manuallyTraining.module.scss';
import classesColor from '../../../../shared/sharedColorConfigurations.module.scss';
import classesComponent from '../../../../shared/sharedComponent.module.scss';
export interface ChillTimeComponentProperties {
  clickHandler: (params: TrainingDto) => void;
  finishHandler: (params: TrainingDto) => void;
  previousTrainingDescriptions: TrainingDescriptionDto[];
  currentTraining?: TrainingDto;
  prevTraining?: TrainingDto;
  nextTraining?: TrainingDto;
  isAutoMove: boolean;
}
export default function ChillTimeComponent({
  clickHandler,
  finishHandler,
  prevTraining,
  nextTraining,
  currentTraining,
  previousTrainingDescriptions,
  isAutoMove,
}: ChillTimeComponentProperties) {
  const [trainingDescription, setTrainingDescription] = useState<TrainingDescriptionDto>();
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [training, setTraining] = useState<TrainingDto>(currentTraining ?? new TrainingObject());

  const startedTime = useRef<DateTime>();
  const buildTraining = function () {
    if (startedTime.current != null) {
      const updatedTraining = {
        ...training,
        chillTime: DateTime.now().diff(startedTime.current).toFormat(longCommonTimeFormat12),
      };
      updatedTraining.trainingDescription = trainingDescription;
      setTraining(updatedTraining);
      return updatedTraining;
    }
  };
  const setTrainingDescriptionWrapper = function (newTrainingDescription: TrainingDescriptionDto) {
    const updatedTraining = {...training, trainingDescriptionId: newTrainingDescription.id};
    if (newTrainingDescription.name) {
      updatedTraining.customTrainingName = newTrainingDescription.name;
    }
    setTraining(updatedTraining);
    setTrainingDescription(newTrainingDescription);
  };
  const nextHandler = function () {
    const newTraining = buildTraining();
    if (newTraining != null) {
      clickHandler(newTraining);
    }
  };
  useEffect(() => {
    startedTime.current = DateTime.now();
    if (currentTraining != null && currentTraining.trainingDescription) {
      setTrainingDescriptionWrapper(currentTraining.trainingDescription);

      const updatedTraining = {...training, counts: currentTraining.counts};
      setTraining(updatedTraining);
    }
  }, []);

  const {t} = useTranslation();
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <div className={classes.trainingInfoWrapper}>
          <div className="row " style={{display: 'flex', justifyContent: 'center'}}>
            <div className="col-12" style={{width: '100%'}}>
              <TagList
                elements={previousTrainingDescriptions}
                onTagClick={newTrainingDescription => {
                  setTrainingDescriptionWrapper(newTrainingDescription);
                }}
              />
            </div>
          </div>
        </div>
        <h2
          className={classesColor.sidebarColor + ' my-4'}
          style={{width: '100%', textAlign: 'center'}}>
          {t('Chill')}
        </h2>
        <div className={classes.trainingInfoWrapper}>
          <div style={{width: '100%', textAlign: 'center'}}>
            {trainingDescription == null ? (
              <TrainingDescriptionInput
                getNewtrainingDescription={newTrainingDescription => {
                  setTrainingDescriptionWrapper(newTrainingDescription);
                }}
              />
            ) : (
              <TrainingStateComponent
                onClickHandler={() => {
                  setTrainingDescription(undefined);
                }}
                prevTraining={prevTraining}
                currentTraining={
                  new TrainingObject(
                    currentTraining?.id,
                    trainingDescription?.id,
                    trainingDescription?.name,
                    currentTraining?.counts,
                  )
                }
                nextTraining={nextTraining}></TrainingStateComponent>
            )}
          </div>
        </div>
        {trainingDescription?.typeOfTrainingDescription == 0 && (
          <div className={classes.trainingInfoWrapper + ' mb-4 relative'} style={{width: '100%'}}>
            <label htmlFor="Counts">{t('Counts')} </label>
            <input
              type="number"
              id="weight"
              className={
                classesComponent.transparentInputGrey + ' my-2  px-4 py-2 w-full  h-[42px]'
              }
              name="counts"
              value={training.counts ?? 0}
              onChange={event => {
                const updatedTraining = {...training, counts: Number.parseInt(event.target.value)};
                setTraining(updatedTraining);
              }}
            />
          </div>
        )}
        <div className={classes.trainingInfoWrapper}>
          <Timer
            recommendedTime={currentTraining?.chillTime}
            onOvertimeHandler={() => {
              if (isAutoMove) {
                nextHandler();
              }
            }}
          />
        </div>
        <div className={classes.trainingInfoWrapper}>
          <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
            <span
              className={classesColor.sidebarColor + ' mx-4'}
              onClick={() => {
                nextHandler();
              }}>
              <FontAwesomeIcon
                style={{fontSize: '40px'}}
                className="  cursor-pointer"
                icon={faChevronCircleRight}
              />
            </span>
            <span
              className={classesColor.sidebarColor + ' mx-4'}
              onClick={() => {
                setIsConfirmation(true);
              }}>
              <FontAwesomeIcon
                style={{fontSize: '40px'}}
                className=" cursor-pointer"
                icon={faCircleCheck}
              />
            </span>
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={isConfirmation}
        handleAgree={function () {
          finishHandler(training);
          setIsConfirmation(false);
        }}
        handleClose={function (): void {
          setIsConfirmation(false);
        }}></ConfirmModal>
    </div>
  );
}
