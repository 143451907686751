import {AppDispatch} from '../../store';
import axios from '../../axios';
import {queries} from '@testing-library/react';
import {CreateRestaurantDTO, RestaurantDTO} from '../../models/mealModel';
import {restaurantSlice} from '../../store/meals/slices/restaurant.slice';
export const getRestaurants = (city?: string, name?: string, country?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<RestaurantDTO[]>('api/restaurant', {
        params: {
          city: city,
          name: name,
          country: country,
        },
      });
      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantsFetchingError);
      } else {
        dispatch(restaurantSlice.actions.restaurantsFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getRestaurant = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<RestaurantDTO>('api/restaurant/' + id);
      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantFetchingError);
      } else {
        dispatch(restaurantSlice.actions.restaurantFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addRestaurant = (restaurant: CreateRestaurantDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<RestaurantDTO[]>('api/restaurant', restaurant);
      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantFetchingError);
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
