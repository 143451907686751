import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {setupStore} from './store';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormProvider, useForm} from 'react-hook-form';
const store = setupStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register(
//       '/firebase-messaging-sw.js?config=' +
//         encodeURIComponent(
//           JSON.stringify({
//             apiKey: process.env.REACT_APP_API_KEY,
//             authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//             projectId: process.env.REACT_APP_PROJECT_ID,
//             storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//             messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//             appId: process.env.REACT_APP_APP_ID,
//             measurementId: process.env.REACT_APP_MEASUREMENT_ID,
//           }),
//         ),
//     )
//     .then(registration => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch(error => {
//       console.error('Service Worker registration failed:', error);
//     });
// }
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.Suspense fallback={<div>Loading...</div>}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
