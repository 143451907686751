import React, {useState, useEffect, useRef} from 'react';
import {DateTime} from 'luxon';
import classes from './historyPage.module.scss';
import {MealDTO} from '../../models/mealModel';
import {getMeals} from '../../actions/meals/meal.action';
import {useAppDispatch} from '../../hooks/redux';
import ScrollableBody from '../../components/common/scrollbar/scrollbar.component';
import {useTranslation} from 'react-i18next';
import classColors from '../../components/shared/sharedColorConfigurations.module.scss';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../store/account/slices/login.slice';
import {getLastDateForUser} from '../../utils/accountUtil';
import {shortCommonDateFormat} from '../../constants/common/dateFormat';
import {createTotals} from '../../utils/mealUtil';
import {Totals} from '../../components/meals/meals/totalMealDayComponent/totalMealDay.component';
import classesColor from '../../components/shared/sharedColorConfigurations.module.scss';
import {GetWeightForUser} from '../../actions/account/account.actions';
import {WeightRecord} from '../../models/strict/accountStrictModel';
export interface HistoryPagePageComponentProperties {
  id?: string;
}

interface DateItem {
  date: string;
  meals: MealDTO[] | null;
  totals: {
    proteins: number | undefined;
    fats: number | undefined;
    carbohydrates: number | undefined;
    calories: number | undefined;
  };
}

function HistoryPage({id}: HistoryPagePageComponentProperties) {
  const [currentDate] = useState(DateTime.now().plus({day: 1}));
  const [weights, setWeights] = useState<WeightRecord>();
  const user = useSelector(selectCurrentUser);
  const [dates, setDates] = useState<DateItem[]>([
    {
      date: currentDate.toFormat('MM.dd.yyyy'),
      meals: null,
      totals: {proteins: 0, fats: 0, carbohydrates: 0, calories: 0},
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [deficit7, setDeficit7] = useState<Totals>();
  const [deficit30, setDeficit30] = useState<Totals>();
  const [weight, setWeight] = useState(0);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  function getWeight() {
    if (user?.weights != null) {
      let dateWeight = user?.weights![currentDate.toFormat(shortCommonDateFormat)];
      if (dateWeight == null) {
        const lastDate = getLastDateForUser(user);
        if (lastDate != null) {
          dateWeight = user?.weights![lastDate];
        } else {
          dateWeight = 0;
        }
      }
      setWeight(dateWeight);
    }
  }
  const loadMoreData = async () => {
    if (loading) return;
    setLoading(true);

    const lastDate = DateTime.fromFormat(dates[dates.length - 1].date, 'MM.dd.yyyy');
    if (id != null) {
      dispatch(GetWeightForUser(id)).then(f => setWeights(f));
      const fromDate = lastDate.minus({days: 30}).toFormat('MM.dd.yyyy');
      const toDate = lastDate.toFormat('MM.dd.yyyy');
      const totalsForWeight = createTotals(user, weight);
      dispatch(getMeals(fromDate, id, toDate))
        .then(meals => {
          if (meals) {
            const newDates: DateItem[] = [];
            let mealCountLast30Days = 0;
            let mealCountLast7Days = 0;

            let totals30Days = {proteins: 0, fats: 0, carbohydrates: 0, calories: 0};
            let totals7Days = {proteins: 0, fats: 0, carbohydrates: 0, calories: 0};

            for (let i = 0; i < 30; i++) {
              const newDate = lastDate.minus({days: i}).toFormat('MM.dd.yyyy');
              const mealsForDate = meals.filter(
                meal => DateTime.fromISO(meal.eatTime ?? '').toFormat('MM.dd.yyyy') === newDate,
              );

              const totals = mealsForDate.reduce(
                (acc, meal) => ({
                  proteins: (acc.proteins ?? 0) + (meal.proteins ?? 0),
                  fats: (acc.fats ?? 0) + (meal.fats ?? 0),
                  carbohydrates: (acc.carbohydrates ?? 0) + (meal.carbohydrates ?? 0),
                  calories: (acc.calories ?? 0) + (meal.calories ?? 0),
                }),
                {proteins: 0, fats: 0, carbohydrates: 0, calories: 0},
              );

              if (mealsForDate.length > 0) {
                mealCountLast30Days++;
                if (mealCountLast7Days < 7) mealCountLast7Days++;

                totals30Days = {
                  proteins: (totals30Days.proteins ?? 0) + (totals.proteins ?? 0),
                  fats: (totals30Days.fats ?? 0) + (totals.fats ?? 0),
                  carbohydrates: (totals30Days.carbohydrates ?? 0) + (totals.carbohydrates ?? 0),
                  calories: (totals30Days.calories ?? 0) + (totals.calories ?? 0),
                };

                if (i < 7) {
                  totals7Days = {
                    proteins: (totals7Days.proteins ?? 0) + (totals.proteins ?? 0),
                    fats: (totals7Days.fats ?? 0) + (totals.fats ?? 0),
                    carbohydrates: (totals7Days.carbohydrates ?? 0) + (totals.carbohydrates ?? 0),
                    calories: (totals7Days.calories ?? 0) + (totals.calories ?? 0),
                  };
                }
              }

              newDates.push({
                date: newDate,
                meals: mealsForDate.length > 0 ? mealsForDate : null,
                totals: {
                  proteins: totals.proteins ?? 0,
                  fats: totals.fats ?? 0,
                  carbohydrates: totals.carbohydrates ?? 0,
                  calories: totals.calories ?? 0,
                },
              });
            }

            const totalGoals30Days = {
              proteins: totalsForWeight.proteins * mealCountLast30Days,
              fats: totalsForWeight.fats * mealCountLast30Days,
              carbohydrates: totalsForWeight.carbohydrates * mealCountLast30Days,
              calories: totalsForWeight.calories * mealCountLast30Days,
            };

            const totalGoals7Days = {
              proteins: totalsForWeight.proteins * mealCountLast7Days,
              fats: totalsForWeight.fats * mealCountLast7Days,
              carbohydrates: totalsForWeight.carbohydrates * mealCountLast7Days,
              calories: totalsForWeight.calories * mealCountLast7Days,
            };

            const deficit30Days = {
              proteins: totalGoals30Days.proteins - totals30Days.proteins,
              fats: totalGoals30Days.fats - totals30Days.fats,
              carbohydrates: totalGoals30Days.carbohydrates - totals30Days.carbohydrates,
              calories: totalGoals30Days.calories - totals30Days.calories,
            };

            const deficit7Days = {
              proteins: totalGoals7Days.proteins - totals7Days.proteins,
              fats: totalGoals7Days.fats - totals7Days.fats,
              carbohydrates: totalGoals7Days.carbohydrates - totals7Days.carbohydrates,
              calories: totalGoals7Days.calories - totals7Days.calories,
            };
            setDeficit7(deficit7Days);
            setDeficit30(deficit30Days);

            setDates(prev => [...prev, ...newDates]);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
      loadMoreData();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dates, loading]);

  useEffect(() => {
    loadMoreData();
  }, []);
  const {t} = useTranslation();
  return (
    <div
      className="blockGreyWrapper relative col-8"
      style={{marginLeft: 'auto', marginRight: 'auto'}}>
      {deficit7 && deficit30 && (
        <div className={classes.deficitWrapper}>
          {deficit7 && (
            <>
              <div>
                <div className={classColors.sidebarColor}>{t('deficit calories for  7 ')}:</div>
                <div style={{color: 'white'}}>
                  {new Intl.NumberFormat('de-DE').format(Math.round(deficit7.calories ?? 0))}
                </div>
              </div>
            </>
          )}

          {deficit30 && (
            <>
              <div>
                <div className={classColors.sidebarColor}>{t('deficit calories for 30 ')}:</div>
                <div style={{color: 'white'}}>
                  {new Intl.NumberFormat('de-DE').format(Math.round(deficit30.calories ?? 0))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div ref={containerRef} className={classes.container}>
        <div className={classes.line}>
          {dates.map((item, index) => (
            <div key={index} className={classes.dateContainer}>
              <div className={classes.horizontalBranch}></div>
              <div className={classes.date}>{item.date}</div>
              {item.meals && item.meals.length > 0 && (
                <div className={classes.mealList}>
                  {/* {item.meals.map(meal => (
                  <div key={meal.id} className={classes.mealItem}>
                  <div>{meal.eatTime}</div>
                  <div>Proteins: {meal.proteins}</div>
                  <div>Fats: {meal.fats}</div>
                  <div>Carbohydrates: {meal.carbohydrates}</div>
                  <div>Calories: {meal.calories}</div>
                  </div>
                  ))} */}
                  <div className={classes.totals}>
                    <div>
                      <span className={classColors.sidebarColor + ' mx-2'}>
                        {' '}
                        {t('Total Proteins')}:
                      </span>
                      {Math.round(item.totals.proteins ?? 0)}
                    </div>
                    <div>
                      <span className={classColors.sidebarColor + ' mx-2'}>
                        {' '}
                        {t('Total Fats')}:
                      </span>
                      {Math.round(item.totals.fats ?? 0)}
                    </div>
                    <div>
                      <span className={classColors.sidebarColor + ' mx-2'}>
                        {' '}
                        {t('Total Carbohydrates')}:
                      </span>{' '}
                      {Math.round(item.totals.carbohydrates ?? 0)}
                    </div>
                    <div>
                      <span className={classColors.sidebarColor + ' mx-2'}>
                        {t('Total Calories')}:
                      </span>
                      {Math.round(item.totals.calories ?? 0)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {loading && <div className={classes.loading}>Loading...</div>}
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
