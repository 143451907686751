import React from 'react';
import classes from './tabs.module.scss'; // Import the CSS module

interface TabsProps {
  values: string[];
  selectedTab: string;
  OnClickHandler: (value: string) => void;
}

export default function Tabs({selectedTab, values, OnClickHandler}: TabsProps) {
  return (
    <div className={classes.tabs}>
      {values.map((f, index) => {
        return (
          <button
            key={index}
            className={classes.tabButton + ` ${selectedTab === f ? classes.active : ''}`}
            onClick={() => OnClickHandler(f)}>
            {f}
          </button>
        );
      })}
    </div>
  );
}
