/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import classes from './styles/modalWrapper.module.scss';
import SummaryComponent from '../../trainings/realTimeTraining/manuallyTraining/summary/summary.component';
import {useTranslation} from 'react-i18next';
import {Box, Modal} from '@mui/material';
import ModalWrapper from './modalWrapper.component';
import classesModal from '../../common/modal/styles/modalShared.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
export interface ConfirmModalProperties {
  handleAgree: (params: any) => any;
  handleClose: () => void;
  textConfirmation?: string;
  isOpen: boolean;
}
export default function ConfirmModal({
  handleAgree,
  handleClose,
  isOpen,
  textConfirmation,
}: ConfirmModalProperties) {
  const {t} = useTranslation();
  const wrapperHandleClose = () => {
    handleClose();
  };

  return (
    <ModalWrapper handleClose={wrapperHandleClose} isOpen={isOpen}>
      <div
        className={'greyMenuBackground'}
        style={{width: '300px', height: '150px', padding: '10px 20px', position: 'relative'}}>
        {textConfirmation == null && <span style={{color: 'white'}}>{t('AreYouSure')}</span>}
        {textConfirmation && <span style={{color: 'white'}}>{t(textConfirmation)}</span>}
        <span
          className={'color-white  ' + classesModal.saveBtn}
          style={{position: 'absolute', bottom: 0}}
          onClick={handleAgree}>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </div>
    </ModalWrapper>
  );
}
