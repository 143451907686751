import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
import classes from '../foods/foodInput/foodInput.module.scss';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import ScrollableBody from '../../common/scrollbar/scrollbar.component';
import {FoodDTO} from '../../../models/mealModel';

export interface FoodListPresentationProperties {
  foods: FoodDTO[];
  onClickHandler: (clickedFood: FoodDTO) => void;
}

export default function FoodListPresentation({
  foods,
  onClickHandler,
}: FoodListPresentationProperties) {
  return (
    <ScrollableBody>
      {foods?.map(food => (
        <li
          key={food.id}
          onClick={() => {
            onClickHandler(food);
          }}
          style={{borderRadius: '5px', textDecoration: 'none', listStyle: 'none'}}
          className="cursor-pointer background-primary text-white px-3 my-2 p-2">
          <span className="text-white">{food.name}</span>
          {food.description && (
            <div style={{zIndex: 1}} className={classes.foodDescription + ' w-full color-primary'}>
              {food.description}
            </div>
          )}
        </li>
      ))}{' '}
    </ScrollableBody>
  );
}
