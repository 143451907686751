/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ChangeEvent, useEffect, useState, useTransition} from 'react';
import ModalWrapper from '../../common/modal/modalWrapper.component';
import {SetDto, TrainingDto} from '../../../models/trainingModel';
import {SetComponent} from './set.component';
import AddEditSetComponent from './addEditSet/addEditSet.component';
import {SetObject} from '../../../models/strict/sessionStrictModel';
import {useTranslation} from 'react-i18next';
import classColors from '../../../components/shared/sharedColorConfigurations.module.scss';
import ScrollableBody from '../../common/scrollbar/scrollbar.component';
export interface SetsListProperties {
  list: SetDto[]; // sets
  onChangeSetHandler: (params: any) => any;
  removeSetHandler?: (params: any) => any;
  removeTrainingHandler: (SetDto: SetDto, index: number, setIndex: number) => any;
}
export function SetsWrapper({
  list,
  onChangeSetHandler,
  removeSetHandler,
  removeTrainingHandler,
}: SetsListProperties) {
  const [newSet, setNewSet] = useState<SetDto>();
  const {t} = useTranslation();
  useEffect(() => {
    setNewSet(new SetObject());
  }, []);
  function renderSetsList() {
    return list!.map((set, indexSet) => (
      <SetComponent
        isItEC={false}
        key={set.id}
        set={set}
        editSetHandler={editedSet => {
          setNewSet(editedSet);
        }}
        removeTrainingHandler={function (params: TrainingDto, removedSet: SetDto, index: number) {
          removeTrainingHandler(removedSet, index, indexSet);
        }}
      />
    ));
  }

  return (
    <div>
      <ScrollableBody maxHeight="500px" minHeight="500px">
        {newSet && (
          <AddEditSetComponent
            SetInput={newSet}
            saveHandler={set => {
              onChangeSetHandler(set);
              setNewSet(new SetObject());
            }}></AddEditSetComponent>
        )}
        {list != null && list.length > 0 && (
          <div className={classColors.sidebarColor + ' my-4'}>
            <p>{t('Sets')}</p>
            <div className="mb-4 relative ">{renderSetsList()}</div>
          </div>
        )}
      </ScrollableBody>
    </div>
  );
}
