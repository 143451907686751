import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import SessionWrapper from '../../components/trainings/sessions/sessions/sessionWrapper.component';
import {SessionDto} from '../../models/trainingModel';
import {SetFormula, getCurrentUserInfo} from '../../actions/account/account.actions';
import {useAppDispatch} from '../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {deleteSession, getSessions} from '../../actions/trainings/session.action';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import {MealDTO} from '../../models/mealModel';
import {addMeals, getMeals} from '../../actions/meals/meal.action';
import WeightInput from '../../components/common/weightInput.component';
import {CommonSelectInput} from '../../components/common/input/commonSelectInput.component';
import {createKeyValuePairsFromEnum} from '../../utils/enumUtil';
import {KcalFormulaEnum} from '../../models/accountModel';
import {keyValuePair} from '../../models/common/keyValuePair';
import DateNavigator from '../../components/common/dateNavigator.component';
import {DateTime} from 'luxon';
import {largeCommonDateFormat, shortCommonDateFormat} from '../../constants/common/dateFormat';
import {LastSessionComponent} from '../../components/trainings/sessions/sessions/lastSession/lastSession.component';
import {StartTrainingComponent} from '../../components/trainings/trainings/startTraining/startTraining.component';
import MealsManager from '../../components/meals/meals/mealsManager/mealsManager.component';
import ModalWrapper from '../../components/common/modal/modalWrapper.component';
import AddEditMealComponent from '../../components/meals/meals/addEditMealComponent/addEditMealComponent';
import {
  CreateMealObject,
  CreateUpdateFoodMealObject,
  MealObject,
} from '../../models/strict/mealStrictModel';
import {getLastDateForUser} from '../../utils/accountUtil';

function MainPage() {
  const [sessions, SetSessions] = useState<SessionDto[]>();
  const [lastMeals, SetMeals] = useState<MealDTO[]>();
  const [lastSession, setLastSession] = useState<SessionDto>();
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const [weight, setWeight] = useState<number>();
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const userId = useSelector(selectCurrentUserId);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const refreshMeal = () => {
    const previousDay = currentDate.minus({day: 1}).toFormat(shortCommonDateFormat);
    const nextDay = currentDate.plus({day: 1}).toFormat(shortCommonDateFormat);
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    dispatch(getMeals(previousDay, userId, nextDay, {signal: newAbortController.signal})).then(
      result => {
        SetMeals(result);
      },
    );
  };
  function getWeight() {
    if (user?.weights != null) {
      let dateWeight = user?.weights![currentDate.toFormat(shortCommonDateFormat)];
      if (dateWeight == null) {
        const lastDate = getLastDateForUser(user);
        if (lastDate != null) {
          dateWeight = user?.weights![lastDate];
        } else {
          dateWeight = 0;
        }
      }
      setWeight(dateWeight);
    }
  }
  const refreshHandler = () => {
    const previousDay = currentDate.minus({day: 1}).toFormat(shortCommonDateFormat);
    const nextDay = currentDate.plus({day: 1}).toFormat(shortCommonDateFormat);
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    dispatch(getSessions(userId, previousDay, nextDay, {signal: newAbortController.signal})).then(
      result => {
        SetSessions(result);
      },
    );
    refreshMeal();
    getWeight();
  };

  useEffect(() => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    dispatch(
      getSessions(userId, undefined, undefined, {signal: newAbortController.signal}, 1, 1),
    ).then(f => {
      if (f != null && f.length > 0) {
        setLastSession(f[0]);
      }
    });
    dispatch(getCurrentUserInfo());
  }, []);
  useEffect(() => {
    refreshHandler();
  }, [currentDate]);
  const [NewMeal, setNewMeal] = useState<MealDTO>();
  const getSelectedKeyValuePair = (): keyValuePair | undefined => {
    const keyValuePairs = createKeyValuePairsFromEnum(KcalFormulaEnum);
    return keyValuePairs.find(pair => pair.key === user.kcalFormulaEnum);
  };
  function AddNewMeal() {
    setNewMeal(new MealObject());
  }
  function SaveAddNewMeal(NewFoodMeal: MealDTO) {
    console.log(NewFoodMeal);
    const requestDTO = new CreateMealObject(
      undefined,
      undefined,
      NewFoodMeal.eatTime,
      NewFoodMeal.foods?.map(
        f =>
          new CreateUpdateFoodMealObject(
            undefined,
            undefined,
            f.food?.id ? f.food.id : undefined,
            f.weight,
          ),
      ),
    );
    requestDTO.eatTime = DateTime.fromFormat(requestDTO.eatTime!, largeCommonDateFormat).toISO()!;
    dispatch(addMeals(requestDTO));
    setNewMeal(undefined);
    refreshHandler();
  }

  return (
    <div
      className="content flex-row w-full  row px-2"
      style={{height: '100vh', justifyContent: 'space-between;', flexDirection: 'column'}}>
      {NewMeal && (
        <ModalWrapper
          handleClose={() => {
            setNewMeal(undefined);
          }}
          isOpen={NewMeal != null}>
          <AddEditMealComponent
            meal={NewMeal}
            choosenDate={currentDate}
            saveHandler={SaveAddNewMeal}></AddEditMealComponent>
        </ModalWrapper>
      )}
      <div
        className="row col-12"
        style={{
          marginTop: '40px',
          display: 'flex',
          justifyContent: 'space-around',
          paddingRight: '0',
        }}>
        {lastSession != null && (
          <div className="blockGreyWrapper col-lg-12  col-xl-5">
            <LastSessionComponent
              session={lastSession}
              refreshHandle={function (): void {
                refreshHandler();
              }}
            />
          </div>
        )}
        <div
          className={
            'blockGreyWrapper ' +
            (lastSession == null || lastSession == undefined ? 'col-12' : 'col-xl-5  col-lg-12')
          }>
          <StartTrainingComponent
            onClickHandler={() => {
              window.location.href = '/realtimetrainings/';
            }}
          />
        </div>
      </div>
      <div
        className="row col-12"
        style={{marginTop: '40px', display: 'flex', justifyContent: 'space-around'}}>
        <MealsManager
          user={user}
          weight={weight}
          list={lastMeals ?? []}
          callRefresh={() => {
            refreshMeal();
          }}
          onAddMealHandler={function (): void {
            setNewMeal(new MealObject());
          }}></MealsManager>
      </div>
      {/* {currentDate != null && (
        <DateNavigator
          currentDate={currentDate}
          onDateChange={(newDate: DateTime) => {
            setCurrentDate(newDate);
          }}
        />
      )}
      <div className="flex" style={{justifyContent: 'space-between'}}>
        <WeightInput user={user} />
        <CommonSelectInput
          selectedValue={getSelectedKeyValuePair()}
          inputValues={createKeyValuePairsFromEnum(KcalFormulaEnum)}
          onUpdate={function (value: string): void {
            const newValue = Number.parseInt(value);
            dispatch(SetFormula(newValue));
          }}
          error={false}
          errorMessage={''}
        />
      </div>
      <div
        className="col-xs-12 col-lg-5 primaryBackground color-white p-2 border-custom mx-2"
        style={{height: 'fit-content'}}>
        <MealWrapper
          refreshHandle={() => {
            refreshHandler();
          }}
          isForToday={true}
          user={user}
          list={lastMeals}
          weight={user.weights?.last}
        />
      </div>

      <div
        className="col-xs-12 primaryBackground color-white border-custom  p-2  col-lg-5 mx-2"
        style={{height: 'fit-content'}}>
        <SessionWrapper
          refreshHandle={() => {
            refreshHandler();
          }}
          onDeleteHandler={(sessionId: string) => {
            dispatch(deleteSession(sessionId)).then(f => {
              refreshHandler();
            });
          }}
          user={user}
          list={sessions}
        />
      </div> */}
    </div>
  );
}

export default MainPage;
