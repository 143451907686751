/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import classes from './styles/modalWrapper.module.scss';
import {Box, Modal} from '@mui/material';
export interface ModalWrapperProperties {
  handleClose: (params: any) => any;
  children: React.ReactNode;
  headerName?: string;
  hideButtonPanel?: boolean;
  isOpen: boolean;
}
export default function ModalWrapper({
  handleClose,
  children,
  headerName,
  isOpen,
}: ModalWrapperProperties) {
  const wrapperHandleClose = () => {
    if (handleClose != null) {
      handleClose(undefined);
    }
  };
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgb(111 20 26)',
    border: '2px solid rgb(111 20 26)',
    boxShadow: 55,
  };
  return (
    <Modal
      open={isOpen}
      onClose={wrapperHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>{children}</Box>
    </Modal>
  );
}
