import React from 'react';
import styles from './speedometer.module.scss'; // Import the CSS module

interface SpeedometerProps {
  value: number;
  maxValue: number;
  size?: number;
}

export default function Speedometer({value, maxValue, size = 100}: SpeedometerProps) {
  const clampedValue = Math.min(Math.max(value, 0), maxValue);

  const needleRotation = -90 + (clampedValue / maxValue) * 180;

  return (
    <div className={styles.speedometer} style={{width: size, height: size / 2}}>
      <svg
        width={size}
        height={size / 2}
        viewBox={`0 0 ${size} ${size / 2}`}
        className={styles.dial}>
        <path
          d={`M ${size / 10},${size / 2} 
              A ${size / 2.5},${size / 2.5} 0 0,1 ${size - size / 10},${size / 2}`}
          fill="none"
          stroke="#ccc"
          strokeWidth="5"
        />

        <path
          d={`M ${size / 10},${size / 2} 
              A ${size / 2.5},${size / 2.5} 0 0,1 ${size - size / 10},${size / 2}`}
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="5"
        />
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#DF2935" />
            <stop offset="100%" stopColor="rgb(111 20 26)" />
          </linearGradient>
        </defs>
      </svg>

      <div
        className={styles.needle}
        style={{
          transform: `rotate(${needleRotation}deg)`,
        }}
      />
    </div>
  );
}
