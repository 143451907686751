import React, {ChangeEvent, useEffect, useState} from 'react';
import {TrainingObject} from '../../../models/strict/sessionStrictModel';
import ModalWrapper from '../../common/modal/modalWrapper.component';
import {SetDto, TrainingDto} from '../../../models/trainingModel';
import {TrainingList} from '../trainings/trainingsList.component';
import AddEditTrainingComponent from '../trainings/addEditTraining/addEditTraining.component';

export interface SetProperties {
  set: SetDto; // sets
}
export function SetDayComponent({set}: SetProperties) {
  return <div>{set.id} </div>;
}
