import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {authSlice} from '../../../store/account/slices/login.slice';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import MainLogo from '../../common/logos/mainLogo';
import LevelProgressBar from '../components/level/progressBar/levelProgressBar.component';
import LevelComponent from '../components/level/level/level.component';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBowlFood,
  faCalendar,
  faEnvelopeOpenText,
  faList,
  faMap,
  faRightFromBracket,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import classes from './sidebar.module.scss'; // Import the CSS file

export function SideBar() {
  const dispatch = useAppDispatch();
  const {email, isAuthenticated} = useAppSelector(state => state.authReducer);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close
  const {t} = useTranslation();
  const logoutHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(authSlice.actions.logout());
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (isAuthenticated) {
    return (
      <nav
        className={
          classes.greyMenuBackground +
          'flex  greyMenuBackground justify-between w-full items-center drop-shadow h-[50px] color-white'
        }
        style={{
          marginTop: '10px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <div className={classes.sidebarContent + ` ${isMenuOpen ? 'display-flex' : ''} `}>
            <div onClick={toggleMenu} className={classes.burgerIcon}>
              ☰
            </div>
            <Link to="/" className="mx-2">
              {t('main')}
            </Link>
            <Link to="/calendar" className="mx-2">
              {t('calendar')}
            </Link>
            <Link to="/trainingMap" className={'mx-2 relative ' + classes.underConstruction}>
              {t('trainingMap')}
            </Link>
            <Link to="/trainingPlan" className="mx-2">
              {' '}
              {t('trainingPlan')}
            </Link>
            <Link to="/foodMgmt" className={'mx-2 relative ' + classes.underConstruction}>
              {' '}
              {t('foodManagement')}
            </Link>
            <Link to="/articles" className={'mx-2 relative ' + classes.underConstruction}>
              {t('articles')}
            </Link>
            <Link to="/groups" className="mx-2">
              {t('groups')}
            </Link>
            <a href="#" style={{marginRight: '10px'}} onClick={logoutHandler}>
              {t('Logout')}
            </a>
          </div>
        </div>
      </nav>
    );
  }
  return <div></div>;
}
