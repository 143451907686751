import React from 'react';
import {MealDTO} from '../../../../models/mealModel';
import {useAppDispatch} from '../../../../hooks/redux';
import {MealComponent} from '../mealComponent/mealComponent';
import Speedometer from '../../../common/speedometer/speedometer';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import SpeedometerInfoPanel from '../../../common/speedometerInfoPanel/speedometerInfoPanel.component';
import {useTranslation} from 'react-i18next';
export interface TotalMealDayComponentProperties {
  list: MealDTO[];
  total: Totals;
}
export interface Totals {
  proteins: number;
  fats: number;
  carbohydrates: number;
  calories: number;
}
export default function TotalMealDayComponent({list, total}: TotalMealDayComponentProperties) {
  console.log(list);
  const totals = list.reduce<Totals>(
    (acc, meal: MealDTO) => {
      acc.proteins += meal.proteins || 0;
      acc.fats += meal.fats || 0;
      acc.carbohydrates += meal.carbohydrates || 0;
      acc.calories += meal.calories || 0;
      return acc;
    },
    {proteins: 0, fats: 0, carbohydrates: 0, calories: 0}, // Initial values for the accumulator
  );
  const {t} = useTranslation();
  return (
    <div
      className="mb-4 relative"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
      }}>
      {list && list.length > 0 && (
        <div
          style={{
            display: 'grid',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: 'white',
            gridTemplate: 'auto 1fr / auto 1fr auto',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '.75rem 1rem',
            height: '100%',
            placeItems: 'center',
          }}>
          <div>
            <SpeedometerInfoPanel
              value={Math.round(totals.calories)}
              maxValue={total.calories}
              text={t('сalories')}></SpeedometerInfoPanel>
          </div>
          <div>
            <SpeedometerInfoPanel
              value={Math.round(totals.fats)}
              maxValue={total.fats}
              text={t('fats')}></SpeedometerInfoPanel>
          </div>
          <div>
            <SpeedometerInfoPanel
              value={Math.round(totals.carbohydrates)}
              maxValue={total.carbohydrates}
              text={t('carbohydrates')}></SpeedometerInfoPanel>
          </div>
          <div>
            <SpeedometerInfoPanel
              value={Math.round(totals.proteins)}
              maxValue={total.proteins}
              text={t('proteins')}></SpeedometerInfoPanel>
          </div>
        </div>
      )}
    </div>
  );
}
