import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {LoginUserCommand} from '../../models/accountModel';
import {login} from '../../actions/account/login.actions';
import {useTranslation} from 'react-i18next';
import MainLogo from '../../components/common/logos/mainLogo';
import {AuthTextInput} from './components/authInputs.component';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton.component';
import {backdropClasses} from '@mui/material';
import classes from '../../layouts/auth.layout';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  emailValidationConfig,
  passwordValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import {sendConfirmationEmailAgain} from '../../actions/account/account.actions';
import AuthPageLayout from '../../layouts/auth.layout';
export function EmailIsNotVerifiedPage() {
  const dispatch = useAppDispatch();
  const [emailParam, setEmailParam] = useState<string>('');
  const {t} = useTranslation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    if (email != null) {
      setEmailParam(email);
    }
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  const sendConfirmationEmail = () => {
    dispatch(sendConfirmationEmailAgain(emailParam));
    window.location.href = 'signin';
  };

  return (
    <AuthPageLayout>
      <div className="row col-12">
        <div className="row col-12" style={{marginTop: 30, marginBottom: 80}}>
          <div className="row col-12">
            <div className="offset-1 col-5 text-end">
              <span>{t('EmailIsNotVerified')} </span>
            </div>
            <div className="col-3 text-start">
              <a href="#" onClick={sendConfirmationEmail}>
                {' '}
                {t('SentConfirmationEmail')}{' '}
              </a>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="row col-12">
        <div className="offset-3 col-3 text-end">
          <span>{t('ReturnTo')} </span>
        </div>
        <div className="col-3 text-start">
          <a href="/signin"> {t('LoginPage')} </a>{' '}
        </div>
      </div>
    </AuthPageLayout>
  );
}
