import {
  AuthenticationTokenModel,
  CreateUserCommand,
  LoginUserCommand,
  UserInfoDTO,
  WeightPerDay,
} from '../../models/accountModel';
import {AppDispatch} from '../../store';
import axios from '../../axios';
import {userSlice} from '../../store/account/slices/user.slice';
import {authSlice, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import {ResetPasswordCommand, WeightRecord} from '../../models/strict/accountStrictModel';
import {AuthApi} from './constants';

const accountApiUrl = AuthApi.AuthApiPrefix + '/account';
export const getUserInfo = (userId?: string, email?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<UserInfoDTO>(accountApiUrl, {
        params: {
          userId: userId,
          email: email,
        },
      });
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getCurrentUserInfo = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const userId = useSelector(selectCurrentUserId);
      const response = await axios.get<UserInfoDTO>(accountApiUrl, {
        params: {
          userId: userId,
        },
      });
      dispatch(authSlice.actions.setUserInfoState(response.data));
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const confirmEmail = async (email: string, code: string) => {
  try {
    const response = await axios.post<UserInfoDTO>(accountApiUrl + '/confirmVerification', {
      email: email,
      code: code,
    });
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log('Error Login', e);
  }
};
export const sendConfirmationEmailAgain = (email: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<UserInfoDTO>(accountApiUrl + '/repeatVerificationCode', {
        email: email,
      });
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const sendForgotPassword = (email: string) => {
  return axios.post<UserInfoDTO>(accountApiUrl + '/forgotPassword', {
    email: email,
  });
};
export const resetPassword = (command: ResetPasswordCommand) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<AuthenticationTokenModel>(
        accountApiUrl + '/resetPassword',
        command,
      );
      if (response.status <= 300) {
        dispatch(authSlice.actions.loginSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const SetWeightOfDay = (data: WeightPerDay) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<WeightPerDay>(accountApiUrl + '/setWeight', data);
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const SetFormula = (value: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put(accountApiUrl + '/setFormula?formula=' + value);
      dispatch(authSlice.actions.setKcalFormula(value));
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const GetWeightForUser = (id: string) => {
  return async (dispatch: AppDispatch): Promise<WeightRecord | undefined> => {
    try {
      const response = await axios.get(accountApiUrl + '/getWeightForUser?destUserId=' + id);

      if (response.data) {
        const weightData: WeightRecord = {};

        response.data.forEach((item: {date: string; weight: number}) => {
          weightData[item.date] = item.weight;
        });

        return weightData;
      }
    } catch (e) {
      console.log('Error getting weight for user', e);
    }
  };
};
