import React from 'react';
import styles from './speedometerInfoPanel.module.scss'; // Import the CSS module
import Speedometer from '../speedometer/speedometer';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';

interface SpeedometerInfoPanelProps {
  value: number;
  maxValue: number;
  text: string;
}

export default function SpeedometerInfoPanel({value, maxValue, text}: SpeedometerInfoPanelProps) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <h4 className={classesColor.sidebarColor + ''}>{text}</h4>
      <Speedometer value={value} maxValue={maxValue}></Speedometer>
      <div className="mt-4">
        <span> {Math.round(value)}/</span>
        <span className={classesColor.sidebarColor + ''}>{maxValue}</span>
      </div>
    </div>
  );
}
