import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {LoginUserCommand} from '../../models/accountModel';
import {login} from '../../actions/account/login.actions';
import {useTranslation} from 'react-i18next';
import MainLogo from '../../components/common/logos/mainLogo';
import {AuthTextInput} from './components/authInputs.component';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton.component';
import {backdropClasses} from '@mui/material';
import classes from '../../layouts/auth.module.scss';
import AuthPageLayout from '../../layouts/auth.layout';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  emailValidationConfig,
  passwordValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import GlobalLoader from '../../components/common/loader/globalLoader/globalLoader.component';
export function LoginPage() {
  const dispatch = useAppDispatch();
  const [IsLoading, SetIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
    getFieldState,
  } = useForm<LoginUserCommand>({
    mode: 'onBlur',
  });
  const [form, setForm] = useState<LoginUserCommand>({
    password: '',
    email: '',
  });
  const {t} = useTranslation();
  const emailConfig: ValidationConfig = emailValidationConfig(control);
  const passwordConfig: ValidationConfig = passwordValidationConfig(control);
  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const changeHandler = (name: string, value: string | number) => {
    setForm(prev => ({...prev, [name]: value}));
  };

  const loginHandler = async () => {
    SetIsLoading(true);
    await dispatch(login(form));
    SetIsLoading(false);
  };
  if (IsLoading) {
    return <GlobalLoader></GlobalLoader>;
  }
  return (
    <AuthPageLayout>
      <div style={{width: '100%'}}>
        <form onSubmit={handleSubmit(loginHandler)}>
          <AuthTextInput
            props={emailConfig}
            labelValue="Email"
            placeholder={t('Email')}
            className="col-12"
            onUpdate={value => {
              changeHandler('email', value);
            }}></AuthTextInput>
          <AuthTextInput
            props={passwordConfig}
            labelValue="Password"
            placeholder={t('Password')}
            className="col-12"
            onUpdate={value => {
              changeHandler('password', value);
            }}
            type="password"></AuthTextInput>

          <AuthButton labelValue={t('login')} isSubmit={true} />
        </form>
        <div
          className={classes.authFooter}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            padding: '10px 20px',
          }}>
          <div className="col-6">
            <a href="forgotPassword">{t('Forgot password')}</a>
          </div>
          <div className="col-6 text-end">
            <span>
              {t('Havent an account')} <a href="signup"> {t('SignUp')} </a>
            </span>
          </div>
        </div>

        <AuthWrapperLogo header="Or continue with" />
      </div>
    </AuthPageLayout>
  );
}
