import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ChangeEvent, useState} from 'react';
import {keyValuePair} from '../../../models/common/keyValuePair';
import SelectInput from '@mui/material/Select/SelectInput';
import {SelectCustomInput} from './selectInput.component';
interface CommonSelectInput {
  inputValues: keyValuePair[];
  className?: string;
  labelValue?: string;
  inputType?: string;
  selectedValue?: keyValuePair | undefined;
  onUpdate: (value: string) => void;
  error: boolean;
  errorMessage: string;
}
export function CommonSelectInput({
  inputValues,
  selectedValue,
  onUpdate,
  error,
  errorMessage,
  inputType = 'text',
  className,
  labelValue,
}: CommonSelectInput) {
  return (
    <SelectCustomInput
      inputValues={inputValues}
      onUpdate={onUpdate}
      className=""
      selectedValue={selectedValue}
      error={false}
      errorMessage={errorMessage}
    />
  );
}
