import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TrainingDto} from '../../../../../models/trainingModel';
import Timer from '../../../../common/timer/timer.component';
import '../trainingTime/trainingTime.component.scss';
import {
  faChevronCircleRight,
  faDiagramNext,
  faForward,
  faForwardStep,
  faMailForward,
} from '@fortawesome/free-solid-svg-icons';
import TrainingStateComponent from '../trainingState/trainingState.module';
import {useTranslation} from 'react-i18next';
import classes from '../manuallyTraining.module.scss';
import classesColor from '../../../../shared/sharedColorConfigurations.module.scss';
export interface TrainingTimeComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickHandler: (params: any) => any;
  currentTraining?: TrainingDto;
  prevTraining?: TrainingDto;
  nextTraining?: TrainingDto;
  isAutoMove: boolean;
}
export default function TrainingTimeComponent({
  clickHandler,
  prevTraining,
  nextTraining,
  currentTraining,
  isAutoMove,
}: TrainingTimeComponentProperties) {
  // const [session, setSession] = useState<SessionDto>()
  // useEffect(() => {
  //   console.log(session)
  // }, [session])
  const {t} = useTranslation();
  return (
    <div>
      <div className={classes.trainingInfoWrapper}>
        <TrainingStateComponent
          prevTraining={prevTraining}
          currentTraining={currentTraining}
          nextTraining={nextTraining}></TrainingStateComponent>
      </div>
      <h2
        className={classesColor.sidebarColor + ' my-4'}
        style={{width: '100%', textAlign: 'center'}}>
        {t('Training')}
      </h2>
      <div className={classes.trainingInfoWrapper}>
        <Timer
          recommendedTime={currentTraining?.duration}
          onOvertimeHandler={() => {
            if (isAutoMove) {
              clickHandler(undefined);
            }
          }}
        />
      </div>
      <span className={classes.trainingInfoWrapper} onClick={clickHandler}>
        <FontAwesomeIcon
          className=" cursor-pointer"
          style={{fontSize: '40px'}}
          icon={faChevronCircleRight}
        />
      </span>
    </div>
  );

  return <div></div>;
}
