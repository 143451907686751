import {KcalFormulaEnum, SexEnum, UserInfoDTO, WeightPerDay} from '../accountModel';

export class UserInfoObject implements UserInfoDTO {
  constructor(
    public userName?: string,
    public email?: string,
    public firstName?: string,
    public sessionStreak?: number,
    public lastName?: string,
    public birthOfDate?: string,
    public height?: number,
    public kcalFormulaEnum?: KcalFormulaEnum,
    public weights?: Record<string, number>,
    public sex?: SexEnum,
  ) {}
}
export class WeightPerDayObject implements WeightPerDay {
  constructor(
    public date: string,
    public weight: number,
  ) {}
}
export class ResetPasswordCommand {
  constructor(
    public email: string,
    public code: string,
    public newPassword: string,
  ) {}
}
export class GroupDto {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public userGroups: UserGroupDto[],
  ) {}
}
export enum UserGroupRoleEnum {
  User = 0,
  Trainer = 1,
  Owner = 2,
}
export const getRoleName = (role: UserGroupRoleEnum) => {
  return UserGroupRoleEnum[role];
};
export class CreateGroupDto {
  constructor(
    public name: string,
    public myRole: number,
    public description?: string,
  ) {}
}

export class AddUserToGroupDto {
  constructor(
    public firstName: string,
    public lastName: string,
    public sex: SexEnum,
    public email: string,
    public roles: number[],
    public groupId: string,
    public expirationDate: string | null,
  ) {}
}
// Example of a Status enum, if it's not already defined
export enum Status {
  Waiting,
  Accepted,
  Declined,
  Left,
  // Add other statuses as needed
}
export function getStatusName(status: Status): string {
  return Status[status].toLowerCase();
}
export class ChangeInvitationStatusDto {
  constructor(
    public email: string,
    public code: string,
    public userId: string,
    public groupId: string,
  ) {}
}
export class UserGroupDto {
  constructor(
    public email: string,
    public user: ShortUserDto,
    public userId: string,
    public groupId: string,
    public status: Status,
    public roles: number[],
    public dateJoin: Date,
  ) {}
}
export interface WeightRecord {
  [date: string]: number;
}
export class EditUserGroupDto {
  constructor(
    public userId: string,
    public email: string,
    public groupId?: string,
    public status?: Status,
    public roles?: number[],
  ) {}
}
export class ShortUserDto {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
  ) {}
}
