import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import MealList from '../../components/meals/meals/mealList';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getMeals} from '../../actions/meals/meal.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {
  AddUserToGroupDto,
  CreateGroupDto,
  GroupDto,
  UserGroupRoleEnum,
} from '../../models/strict/accountStrictModel';
import {useTranslation} from 'react-i18next';
import ModalWrapper from '../../components/common/modal/modalWrapper.component';
import CreateGroupComponent from '../../components/account/groups/groupCreation.component';
import {AddGroup, DeleteGroup, getGroups} from '../../actions/account/group.actions';
import GroupItemComponent from '../../components/account/groups/groupsItem/groupsItem.component';
import {AddUserToGroup, DeleteUserGroup} from '../../actions/account/userGroup.actions';
import AddUserToGroupComponent from '../../components/account/groups/addUserToGroup/addUserToGroup.component';
import classesButton from '../../components/common/modal/styles/modalShared.module.scss';
import ScrollableBody from '../../components/common/scrollbar/scrollbar.component';
function GroupMgmtPage() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const userId = useSelector(selectCurrentUserId);
  const [groupId, setGroupId] = useState<string>();
  const [modGroup, setNewGroup] = useState<GroupDto | CreateGroupDto>();
  const [groups, setGroups] = useState<GroupDto[]>();
  const {t} = useTranslation();
  const getGroupsForPage = () => {
    dispatch(getGroups()).then(f => {
      if (f != null) {
        setGroups(f);
      }
    });
  };
  useEffect(() => {
    getGroupsForPage();
  }, []);
  const getCommonRenderPart = () => {
    return (
      <>
        <button
          style={{marginLeft: 'auto', marginRight: '50px', marginTop: '30px'}}
          className={classesButton.addBtn}
          onClick={() => {
            setNewGroup(new CreateGroupDto('', UserGroupRoleEnum.User, ''));
          }}>
          +
        </button>
        <ModalWrapper
          handleClose={function () {
            setNewGroup(undefined);
          }}
          isOpen={modGroup != undefined}>
          <CreateGroupComponent
            saveHandler={schedule => {
              dispatch(AddGroup(schedule)).then(() => {
                getGroupsForPage();
              });
              setNewGroup(undefined);
            }}
          />
        </ModalWrapper>
      </>
    );
  };
  if (groups == null || groups.length == 0) {
    return (
      <div>
        {getCommonRenderPart()}

        <div>{t('YouHaveNothing')}</div>
      </div>
    );
  }
  return (
    <>
      {getCommonRenderPart()}
      {groupId != null && (
        <ModalWrapper
          handleClose={function () {
            setGroupId(undefined);
          }}
          isOpen={groupId != undefined}>
          <AddUserToGroupComponent
            groupId={groupId}
            saveHandler={(newUser: AddUserToGroupDto) => {
              dispatch(AddUserToGroup(newUser)).then(f => {
                getGroupsForPage();
              });
              setGroupId(undefined);
            }}
          />
        </ModalWrapper>
      )}
      {groups != null && (
        <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
          <div className="content flex-row w-full items-center space-x-4">
            {groups.map((f, index) => (
              <GroupItemComponent
                key={index}
                groupDto={f}
                removeUserHandlerById={(id: string) => {
                  dispatch(DeleteUserGroup(f.id, id, undefined)).then(() => {
                    getGroupsForPage();
                  });
                }}
                removeUserHandler={(email: string) => {
                  dispatch(DeleteUserGroup(f.id, undefined, email)).then(() => {
                    getGroupsForPage();
                  });
                }}
                deleteGroupHandler={() => {
                  dispatch(DeleteGroup(f.id)).then(() => {
                    getGroupsForPage();
                  });
                }}
                addUserHandler={function (id: string): void {
                  setGroupId(id);
                }}
              />
            ))}
          </div>
        </div>
      )}
      ;
    </>
  );
}

export default GroupMgmtPage;
