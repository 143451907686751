import React, {ChangeEvent, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import {Macronutrients} from '../../../models/mealModel';
import {useTranslation} from 'react-i18next';
import classesModal from '../../common/modal/styles/modalShared.module.scss';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';
export interface EditMacronutrientsComponentProperties {
  macronutrients: Macronutrients;
}

// eslint-disable-next-line react/display-name
export const EditMacronutrientsComponent = forwardRef(
  ({macronutrients}: EditMacronutrientsComponentProperties, ref) => {
    const [currentMacronutrients, setCurrentMacronutrients] =
      useState<Macronutrients>(macronutrients);
    const {t} = useTranslation();

    useEffect(() => {
      setCurrentMacronutrients(macronutrients);
    }, [macronutrients]);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentMacronutrients(prev => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    };

    // Exposing `getData` to the parent via the ref
    useImperativeHandle(ref, () => ({
      getData() {
        return currentMacronutrients;
      },
      setData(newMacronutrients: Macronutrients) {
        const roundedMacronutrients = {
          proteins: newMacronutrients.proteins
            ? parseFloat(newMacronutrients.proteins.toFixed(1))
            : undefined,
          fats: newMacronutrients.fats ? parseFloat(newMacronutrients.fats.toFixed(1)) : undefined,
          carbohydrates: newMacronutrients.carbohydrates
            ? parseFloat(newMacronutrients.carbohydrates.toFixed(1))
            : undefined,
          calories: newMacronutrients.calories
            ? parseFloat(newMacronutrients.calories.toFixed(1))
            : undefined,
        };
        setCurrentMacronutrients(roundedMacronutrients);
      },
    }));

    return (
      <div className="my-5">
        <div className={'flex mb-4 ' + classesColor.sidebarColor}>{t('HelpFoodAddText')}</div>
        <div className="flex">
          <div className="mx-2">
            <label htmlFor="calories" className="mx-2 flex">
              {t('Calories')}
            </label>
            <input
              type="number"
              id="calories"
              className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
              name="calories"
              value={currentMacronutrients.calories || ''}
              onChange={changeHandler}
            />
          </div>
          <div className="mx-2">
            <label htmlFor="carbohydrates" className="mx-2 flex">
              {t('Carbohydrates')}
            </label>
            <input
              type="number"
              id="carbohydrates"
              className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
              name="carbohydrates"
              value={currentMacronutrients.carbohydrates || ''}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mx-2">
            <label htmlFor="proteins" className=" mx-2 flex">
              {t('Proteins')}
            </label>
            <input
              type="number"
              id="proteins"
              className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
              name="proteins"
              value={currentMacronutrients.proteins || ''}
              onChange={changeHandler}
            />
          </div>
          <div className="mx-2">
            <label htmlFor="fats" className="mx-2 flex">
              {t('Fats')}
            </label>
            <input
              type="number"
              id="fats"
              className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
              name="fats"
              value={currentMacronutrients.fats || ''}
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default EditMacronutrientsComponent;
