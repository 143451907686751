import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {DateTime} from 'luxon';
import {useAppDispatch} from '../../hooks/redux';
import {ScheduledTrainingPlanDto, SessionDto} from '../../models/trainingModel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AddHistoryIcon from '../common/icon/addHistoryIcon/addHistoryIcon.component';
import {faInfo, faXmark} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import classes from './calendarItem.module.scss';
import classColors from '../shared/sharedColorConfigurations.module.scss';
import ModalWrapper from '../common/modal/modalWrapper.component';
import SummaryComponent from '../trainings/realTimeTraining/manuallyTraining/summary/summary.component';
import {getSession, getSessions} from '../../actions/trainings/session.action';
export interface CalendarItemProperties {
  day: DateTime;
  today: DateTime;
  clickDeleteHandler?: (id: string) => void;
  trainings: ScheduledTrainingPlanDto[];
  sessions?: SessionDto[] | undefined;
  openAddScheduledTrainingPlanHandler: (day: DateTime) => void;
}
export default function CalendarItem({
  day,
  today,
  sessions,
  trainings,
  clickDeleteHandler,
  openAddScheduledTrainingPlanHandler,
}: CalendarItemProperties) {
  const dispatch = useAppDispatch();
  const [selectedSession, setSelectedSession] = useState<SessionDto>();
  return (
    <div
      key={day.toISODate()}
      className={classNames(classes.calendarItem, {
        [classes.calendarItemActive]: day.month === today.month,
        [classes.calendarItemInActive]: day.month !== today.month,
      })}>
      <ModalWrapper
        handleClose={() => {
          setSelectedSession(undefined);
        }}
        isOpen={selectedSession != undefined}>
        <SummaryComponent session={selectedSession}></SummaryComponent>
      </ModalWrapper>
      <div
        className={classNames(classes.dayItem, {
          [classes.dayItemActive]: day.month === today.month,
          [classes.dayItemInActive]: day.month !== today.month,
        })}>
        {day.day}
      </div>
      <div style={{marginTop: '40px', display: 'flex', paddingLeft: '20px'}}>
        {sessions?.map(f => {
          return (
            <div
              key={f.id}
              onClick={async () => {
                if (f.id != null) {
                  dispatch(getSession(f.id)).then(result => {
                    if (result != undefined) {
                      setSelectedSession(result);
                    }
                  });
                }
              }}
              className={classes.infoWrapper + ' mx-1'}>
              <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>;
            </div>
          );
        })}
      </div>
      <div className="scheduledTrainingPlanWrapper">
        {trainings?.map(f => {
          return f.plan != null ? (
            <div className="scheduledTrainingPlan">
              {f.plan.name}
              {clickDeleteHandler != null && (
                <span
                  className="color-white"
                  onClick={e => {
                    e.stopPropagation();
                    clickDeleteHandler(f.id!);
                  }}>
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              )}
            </div>
          ) : (
            ''
          );
        })}
      </div>
      <div className={classes.actions}>
        <AddHistoryIcon
          onClickHandler={() => {
            openAddScheduledTrainingPlanHandler(day);
          }}></AddHistoryIcon>
      </div>
    </div>
  );
}
