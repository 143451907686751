/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FoodDTO, FoodMealDTO} from '../../../../models/mealModel';
import FoodInput from '../../foods/foodInput/foodInput';
import {FoodComponent} from '../../foods/foodComponent/foodComponent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWeight, faXmark} from '@fortawesome/free-solid-svg-icons';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import classes from '../foodMeal/foodMealComponent.module.scss';
export interface AddEditFoodMealComponentProperties {
  food: FoodMealDTO;
  clickHandler: (params: any) => any;
  removeHandler: (params: any) => any;
}
export function FoodMealComponent({
  food,
  clickHandler,
  removeHandler,
}: AddEditFoodMealComponentProperties) {
  if (food != null) {
    return (
      <li
        key={food.food?.id}
        onClick={() => {
          if (clickHandler != null) {
            clickHandler(food);
          }
        }}
        className={
          'relative cursor-pointer hover:bg-gray-500 hover:text-white py-2 my-2 ' +
          classes.foodWrapper
        }>
        <div style={{padding: 0}}>
          <FoodComponent
            food={food.food}
            clickHandler={() => {
              clickHandler(food);
            }}
            weight={food.weight}
          />
        </div>
        <p
          className={'absolute top-3 right-3 m-0'}
          onClick={e => {
            e.stopPropagation();
            removeHandler(food);
          }}>
          <FontAwesomeIcon icon={faXmark} />
        </p>
      </li>
    );
  } else {
    return <li> </li>;
  }
}
