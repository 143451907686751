import {useSelector} from 'react-redux';
import {UserInfoDTO} from '../../../../models/accountModel';
import {SessionDto, SetDto} from '../../../../models/trainingModel';
import {SessionList} from './sessionsList.component';
import {useAppDispatch} from '../../../../hooks/redux';
import React, {useEffect, useState} from 'react';
import {CreateSessionObject, SessionObject} from '../../../../models/strict/sessionStrictModel';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import {
  addSessions,
  deleteSession,
  getSessionsStreak,
} from '../../../../actions/trainings/session.action';
import ModalWrapper from '../../../common/modal/modalWrapper.component';
import AddEditSessionComponent from '../createSession/addEditSession.component';
import {DateTime} from 'luxon';
import {largeCommonDateFormat} from '../../../../constants/common/dateFormat';
import {SessionDayComponent} from './sessionDay.component';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClockRotateLeft, faFire} from '@fortawesome/free-solid-svg-icons';
import AddHistoryIcon from '../../../common/icon/addHistoryIcon/addHistoryIcon.component';
import PlayCustomIcon from '../../../common/icon/playIcon.component';
import {FireIcon} from '@heroicons/react/24/solid';
import SessionAddPanelComponent from '../sessionAddPanel.component';

export interface SessionWrapperProperties {
  list?: SessionDto[];
  user: UserInfoDTO;
  choosenDate?: DateTime;
  refreshHandle: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteHandler: (sessionId: string) => any;
}
export default function SessionWrapper({
  user,
  choosenDate,
  list,
  refreshHandle,
  onDeleteHandler,
}: SessionWrapperProperties) {
  const userId = useSelector(selectCurrentUserId);
  const [sessionStreak, setSessionStreak] = useState(0);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  function DeleteSession(Session: SessionDto) {
    console.log(Session);
    if (Session != null) {
      dispatch(deleteSession(Session.id!)).then(() => {
        refreshHandle();
      });
    }
  }
  useEffect(() => {
    dispatch(getSessionsStreak()).then(f => {
      if (f != null) {
        setSessionStreak(f);
      }
    });
  }, []);

  return (
    <div style={{display: 'flex', color: 'color:white', flexDirection: 'column'}}>
      <div>
        <div
          style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
          className="mb-3">
          <span style={{color: 'white'}}>{t('Session')}</span>
          <div className="flex ">
            <div className="mr-2 ">
              {sessionStreak && (
                <div
                  className="relative flex justify-content-center align-items-center border-custom mr-3"
                  style={{
                    flexDirection: 'column',
                    border: 'white 1px solid',
                    padding: '2px 5px',
                    boxShadow: '2px 2px 0px 2px black',
                  }}>
                  <FontAwesomeIcon size={'lg'} icon={faFire} className="color-secondary" />
                  <p style={{color: 'white', margin: 0}} className="color-white">
                    {sessionStreak} {t('dayStreak')}
                  </p>
                </div>
              )}
            </div>
            <SessionAddPanelComponent refreshHandle={refreshHandle} />
          </div>
        </div>
      </div>
      <div className="py-3" style={{background: '#255950', boxShadow: 'inset 0 0 6px 0px black'}}>
        {list &&
          list.map(session => (
            <SessionDayComponent
              key={session.id}
              session={session}
              onDeleteHandler={onDeleteHandler}></SessionDayComponent>
          ))}
      </div>
    </div>
  );
}
